<template>
  <div class="shipping-index-block">
    <div class="wrapper">
      <row>
        <column xs="12">
          <link
            :href="shippingBlockConfig.cssSrc"
            rel="stylesheet">
          <h3 class="ff-flama fs-xxl fw-light tt-uppercase mb-6">SHIPPING INDEX</h3>
          <div
            id="front_composite"
            v-if="shippingBlockConfig && shippingIndexData"
            v-html="shippingIndexData">
          </div>
        </column>
      </row>
    </div>
  </div>
</template>
<script>
import { shippingIndexResolver } from 'global-configs/page-config';
import { Page } from 'global-components';
import { shippingIndexLoader } from 'assets/js/bundled-assets';
import { AssetMixin } from 'global-mixin';

export default {
  name: 'markets-page',
  dataResolver: 'marketsShippingIndex',
  components: {
  },
  extends: Page,
  mixins: [AssetMixin],
  data() {
    return {
      shippingBlockConfig: shippingIndexResolver(this.$store.state.publication.code),
      shippingIndexData: this.$store.state.shippingIndexData
    };
  },
  async assets() {
    await shippingIndexLoader(this.$store);
  }
};
</script>
